<template>
  <div class="evaluate">
    <div class="sctp-container">
      <div class="sctp-mar-tb20 pad-tb15 sctp-bg-white">
        <div class="hairline-round" style="width: 900px;margin: 0 auto;">
          <div class="sctp-flex sctp-flex-sb sctp-bg-gray">
            <div class="sctp-pad-tb10 sctp-tc sctp-bg-red sctp-white" style="width: 100px;">评价宝贝
            </div>
            <div class="sctp-flex sctp-flex-center sctp-pad-lr15">
              卖家：<span>{{ shopInfo && shopInfo.shopname }}</span>
            </div>
          </div>

          <div class="sctp-pad-lr20">
            <template v-for="product in goods">
              <div class="goods-item" :key="product.id">
                <div class="sctp-pad-tb20 sctp-mar-tb15">
                  <div class="flex mg-b15">
                    <el-image
                      style="height: 130px;width: 130px"
                      :src="product.goodsimg"
                      fit="cover"
                    ></el-image>
                    <div class="mg-l15">{{ product.goodsname }}</div>
                  </div>
                  <div class="">

                    <el-form class="goods-item"
                             :model="submitForm[product.id]"
                             :ref="'submitForm' + product.id"
                             :rules="submitFormRule"
                             :key="product.id"
                    >
                      <el-form-item :inline-message="true" class="rate-item"
                                    prop="serviceAttitudeScore">
                        <div class="sctp-flex-aic" style="display: inline-flex">
                          <span class="sctp-mar-r10">服务态度:</span>
                          <el-rate
                            :colors="colors"
                            @change="validateField('submitForm' + product.id, 'serviceAttitudeScore')"
                            v-model="submitForm[product.id].serviceAttitudeScore"
                          >
                          </el-rate>
                        </div>
                      </el-form-item>
                      <el-form-item :inline-message="true" class="rate-item"
                                    prop="completeStatusScore">
                        <div class="sctp-flex-aic" style="display: inline-flex">
                          <span class="sctp-mar-r10">完成程度:</span>
                          <el-rate
                            @change="validateField('submitForm' + product.id, 'completeStatusScore')"
                            :colors="colors"
                            v-model="submitForm[product.id].completeStatusScore"
                          >
                          </el-rate>
                        </div>
                      </el-form-item>
                      <el-form-item :inline-message="true" class="rate-item"
                                    prop="deliveryQualityScore">
                        <div class="sctp-flex-aic" style="display: inline-flex">
                          <span class="sctp-mar-r10">交付质量:</span>
                          <el-rate
                            @change="validateField('submitForm' + product.id, 'deliveryQualityScore')"
                            :colors="colors"
                            v-model="submitForm[product.id].deliveryQualityScore"
                          >
                          </el-rate>
                        </div>
                      </el-form-item>
                      <el-form-item :inline-message="true" class="rate-item"
                                    prop="technicalAbilityScore">
                        <div class="sctp-flex-aic" style="display: inline-flex">
                          <span class="sctp-mar-r10">技术能力:</span>
                          <el-rate
                            @change="validateField('submitForm' + product.id, 'technicalAbilityScore')"
                            :colors="colors"
                            v-model="submitForm[product.id].technicalAbilityScore"
                          >
                          </el-rate>
                        </div>
                      </el-form-item>
                      <el-form-item :inline-message="true" class="rate-item"
                                    prop="communicatioScore">
                        <div class="sctp-flex-aic" style="display: inline-flex">
                          <span class="sctp-mar-r10">沟通能力:</span>
                          <el-rate
                            @change="validateField('submitForm' + product.id, 'communicatioScore')"
                            :colors="colors"
                            v-model="submitForm[product.id].communicatioScore"
                          >
                          </el-rate>
                        </div>
                      </el-form-item>
                      <el-form-item prop="content">
                        <el-input
                          type="textarea"
                          :rows="5"
                          placeholder="请输入评价内容..."
                          autosize
                          maxlength="200"
                          show-word-limit
                          v-model="submitForm[product.id].content"
                        >
                        </el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-upload
                          :http-request="fileUploadFunction"
                          action=""
                          :data="{...uploadConfig.uploadEnum.PRODUCT_EVALUATE}"
                          :limit="4"
                          :class="[{hiddenUpload: submitForm && submitForm[product.id] && submitForm[product.id].fileList && submitForm[product.id].fileList.length >= 4}]"
                          :on-exceed="uploadConfig.fileExceed"
                          :on-error="uploadConfig.uploadFileError"
                          :multiple="true"
                          :on-success="uploadConfig.uploadSuccess.bind(null, {key: product.id})"
                          :before-upload="uploadConfig.beforeUpload.bind(null, {key: product.id})"
                          :on-remove="uploadConfig.onRemove.bind(null, {key: product.id})"
                          list-type="picture-card">
                          <i class="el-icon-plus"></i>
                        </el-upload>

                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div style="padding: 20px 0 20px 250px;">
          <el-button
            type="primary"
            @click="onSubmitClick"
            :loading="!canClickSubmitBtn"
          >
            发表评价
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uploadEnum from "@/resources/js/uploadenum";

const config = require('../../resources/js/config');
export default {
  name: 'evaluate',
  components: {},
  props: ['id'],
  data() {
    let checkRate = function (rule, value, callback) {
      if (value === 0) {
        callback(new Error(rule.message))
      } else {
        callback()
      }
    }
    return {
      canClickSubmitBtn: true,
      order: null,
      goods: null,
      shopInfo: null,
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      submitForm: null,
      submitFormRule: {
        rate: [
          {validator: checkRate, message: '请选择评分', trigger: ['blur', 'change']},
        ],
        serviceAttitudeScore: [
          {validator: checkRate, message: '请选择 服务态度 评分', trigger: ['blur', 'change']},
        ],
        completeStatusScore: [
          {validator: checkRate, message: '请选择 完成程度 评分', trigger: ['blur', 'change']},
        ],
        deliveryQualityScore: [
          {validator: checkRate, message: '请选择 交付质量 评分', trigger: ['blur', 'change']},
        ],
        technicalAbilityScore: [
          {validator: checkRate, message: '请选择 技术能力 评分', trigger: ['blur', 'change']},
        ],
        communicatioScore: [
          {validator: checkRate, message: '请选择 沟通能力 评分', trigger: ['blur', 'change']},
        ],
        content: [
          {required: true, message: '请输入评价', trigger: ['blur', 'change']}
        ],
      },
      uploadConfig: {
        uploadEnum,
        UPLOAD_API: config.UPLOAD_API,
        uploadSuccess: ({key}, response, file, fileList) => {
          this.submitForm[key].fileList = fileList;
          this.$message.success('文件上传成功！');
        },
        onRemove: ({key}, file, fileList) => {
          this.submitForm[key].fileList = fileList
        },
        beforeUpload: ({key}, file) => {
          let result = true;
          if (key === 'annex') {
            const isLtSize = file.size / 1024 / 1024 < 1;
            if (!isLtSize) {
              this.$message.error(`上传文件大小不能超过 1M!`);
              return false;
            }
            result = isLtSize;
          }
          return result && this.$message('文件正在上传') && true;
        },
        fileExceed() {
          this.$message.warning(`文件数量已达上限！`);
        },
        uploadFileError() {
          this.$message.error('文件上传失败')
        },
      },
    }
  },
  methods: {
    validateField(formName, field) {
      this.$refs[formName][0].validateField(field);
    },
    checkForm() {
      const refs = this.$refs;
      const checkArray = [];
      Object.keys(refs).forEach(key => {
        if (key.startsWith('submitForm')) {
          checkArray.push(
            new Promise((resolve, reject) => {
              refs[key][0].validate(res => {
                if (!res) {
                  this.$message.warning('请检查表单内容')
                }
                resolve(res)
              })
            }))
        }
      });
      return Promise.all(checkArray).then(results => {
        return results.every(item => !!item)
      })
    },
    onSubmitClick() {
      this.canClickSubmitBtn = false;
      this.checkForm()
      .then(flag => {
        if (flag) {
          this.doSubmit().finally(() => {
            this.canClickSubmitBtn = true
          })
        } else {
          this.canClickSubmitBtn = true
        }
      })
    },
    doSubmit() {
      const {submitForm} = this;
      const commentList = [];
      Object.keys(submitForm).forEach(key => {
        const item = submitForm[key];
        commentList.push({
          content: item.content,
          ordergoodsid: key,
          score: item.rate,
          serviceAttitudeScore: item.serviceAttitudeScore,
          completeStatusScore: item.completeStatusScore,
          deliveryQualityScore: item.deliveryQualityScore,
          technicalAbilityScore: item.technicalAbilityScore,
          communicatioScore: item.communicatioScore,
          pictures: item.fileList.map(file => file.response.src).join(',')
        })
      });
      return this.$request.post({
        reqcode: '1082',
        reqdesc: '订单评价',
        commentList,
        userId: this.user.userId,
        orderId: this.id
      }).then(res => {
        this.$message.success('评价成功');
        this.delayToPage('/user/order')
      })
    },
    /**
     * 获取订单详情
     */
    getOrderDetails() {
      this.$request.post({
        reqcode: '1024',
        reqdesc: '订单详情',
        orderId: this.id
      }).then(res => {
        const {order, goods, shopInfo} = res.data;
        const submitForm = {};
        if (goods) {
          goods.forEach(item => {
            item.goodsimg =  item.goodsimg;
            submitForm[item.id] = {
              rate: null,
              serviceAttitudeScore: null,
              completeStatusScore: null,
              deliveryQualityScore: null,
              technicalAbilityScore: null,
              communicatioScore: null,
              content: null,
              fileList: []
            }
          })
        }
        this.order = order;
        this.goods = goods;
        this.shopInfo = shopInfo;
        this.submitForm = submitForm
      })
    }
  },
  computed: {},
  beforeMount() {
    this.getOrderDetails()
  }
}
</script>

<style scoped lang="scss">
.evaluate {
  & /deep/ .el-textarea__inner {
    height: 100% !important;
  }

  .rate-item {
    margin-bottom: 5px;

    /deep/ .el-form-item__content {
      line-height: 1.5 !important;
    }
  }

  .goods-item + .goods-item {
    border-top: #ccc 1px dashed;
  }
}


</style>
